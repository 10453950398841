@import "./src/assets/styles/index.scss";
.index {
    // width: var(--body-width);
    margin: auto;

    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
        text-align: center;
    }

    &-carousel {
        background: $logoColor;
    }

    &-companyProfile {
        padding-top: 60px;

        &-body {
            width: var(--body-width);
            margin: auto;
            padding: 20px 0;
            padding-bottom: 80px;

            &-top {
                width: 100%;
                display: flex;
                padding: 20px 0;
                height: 100%;

                >div {
                    box-sizing: border-box;
                    width: 50%;
                    padding: 20px 40px;
                }

                &-left {
                    display: flex;
                    width: 100%;
                    background: $footersColor;
                }

                &-right {
                    &-font {
                        padding: 20px;
                        font-size: 14px;
                        line-height: 28px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.8);
                    }

                    &-bottom {
                        width: 36px;
                        height: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        border-radius: 50%;
                        margin-top: 10px;
                        background: $activeColor;
                    }
                }
            }

        }
    }

    &-item-header {
        width: 100%;
        border-bottom: 2px rgba(141, 141, 141, 0.1) solid;
        box-sizing: border-box;
        position: relative;
        z-index: 9;

        &-title {
            width: var(--body-width);
            margin: auto;
            display: flex;
            flex-wrap: wrap;

            >div {
                display: inline-flex;
                align-items: center;
                border-bottom: 4px rgb(157, 191, 228) solid;
                margin-bottom: -1px;

                span {
                    font-size: 20px;
                    margin-left: 8px;
                    margin-top: 10px;
                    font-weight: 300;
                    color: #8d8d8d;
                }

                p {
                    font-size: 24px;
                    margin: 12px 0;
                }
            }
        }
    }

    &-solution {
        // background: $greyColor;
        background-image: url('../../assets/images/bj01.jpg');
        background-size: cover;
        width: 100%;
        padding-top: 60px;

        &-body {
            width: var(--body-width);
            margin: auto;
            padding: 80px 0;
            &-text {
                padding: 20px;
                padding-top: 40px;
                display: flex;
                flex-direction: row;
                h4 {
                    margin-top: 0;
                    color: #fff;
                }

                span {
                    color: #aaaaaa;
                    font-size: 14px;
                }

                &-tiem {
                    display: flex;
                    color: #8d8d8d;
                    font-size: 18px;
                }

                &-tiem::after {
                    content: '';
                    display: block;
                    height: 60px;
                    width: 1px;
                    margin: 0 20px;
                    background: #dbdbdb;
                }

                &-button {
                    width: 36px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    margin-top: 10px;
                    margin-top: 40px;
                    margin-left: -10px;
                    cursor: pointer;
                }

                &-button:hover {
                    background: $activeColor;
                    color: #fff;
                }
            }
            
        }
        &-about{
            width: var(--body-width);
            padding-bottom: 80px;
            margin: auto;
            display: flex;
            justify-content: space-between;
            >div{
                width: 50%;
            }
            img{
                margin-left: 40px;
            }
        }
    }

    &-productCenter {
        // margin-top: 160px;
        padding-top: 60px;
        width: 100%;
        position: relative;

        &-body {
            position: relative;
            z-index: 2;
            width: var(--body-width);
            margin: auto;

            .index-solution-body-indicator {
                div {
                    >i {
                        background: rgb(202, 202, 202);
                    }
                }
            }
        }

    }

    &-news {
        padding-top: 60px;
        width: 100%;
        background: url('../../assets/images/bj01.jpg');
        background-size: 100%;

        &-newsbody {
            width: var(--body-width);
            margin: auto;
            padding: 80px 0;

            img {
                width: 100%;
            }

            &-item {
                text-align: left;
                height: 100%;
                // background: rgba(255, 255, 255,0.5);
                background: #fff;
                width: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;

                &-text {
                    padding: 20px;
                    padding-top: 40px;
                    display: flex;

                    h4 {
                        margin-top: 0;
                    }

                    span {
                        color: #aaaaaa;
                        font-size: 14px;
                    }

                    &-tiem {
                        display: flex;
                        color: #8d8d8d;
                        font-size: 18px;
                    }

                    &-tiem::after {
                        content: '';
                        display: block;
                        height: 60px;
                        width: 1px;
                        margin: 0 20px;
                        background: #dbdbdb;
                    }

                    &-button {
                        width: 36px;
                        height: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-top: 10px;
                        margin-top: 40px;
                        margin-left: -10px;
                        cursor: pointer;
                        color: #fff;
                        background: $activeColor;
                    }

                }
            }
        }
    }
}